/*
* BASE DEPENDENCIES
*/ /*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.input {
  transition: border-color 0.11s ease, box-shadow 0.21s ease;
  box-shadow: none;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  border-color: hsl(0deg, 0%, 79%);
  border-style: solid;
  border-width: 1px;
  background-color: hsl(0deg, 0%, 100%);
  color: hsl(0deg, 0%, 9%);
  background-image: none;
  -webkit-appearance: none;
  appearance: none;
}
.input:focus {
  border-color: hsl(218deg, 76%, 67%);
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}
[disabled].input {
  border-color: hsl(0deg, 0%, 89%);
  background-color: hsl(0deg, 0%, 95%);
  color: #303030;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
/*
* LAYOUT
*/
.input {
  min-height: 46px;
  padding: 0.3em 1em;
}
.input.-main {
  min-height: 64px;
  font-size: 1.15em;
}

.form {
  padding: 0;
  margin: 0 auto 2em;
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 80rem;
  /* &__fieldset {
  	grid-column: span 2;
  	padding: .85em 1.5em .625em;
  	border: 1px $form-item-border-color solid;
  	border-radius: $form-item-border-radius;
  }

  &__legend {
  	padding: 0 .5em;
  	font-size: 1.35rem;
  	font-weight: bold;
  } */
}
@media screen and (min-width: 45rem) {
  .form {
    grid-template-columns: auto auto;
  }
}
.form__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media screen and (min-width: 40rem) {
  .form__section--full {
    grid-column: span 2;
  }
}
.form__section--to-center {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.form__label {
  display: block;
  margin-bottom: 0.4em;
  cursor: default;
}
.form__message--has-error {
  color: rgb(161, 28, 28);
}